#image-map {
    width: 100%;
    height: 430px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    background-color: #000;
    background-image: url('../images/stars.png');
    background-position: center;
    background-size: 100vh;
    background-repeat: repeat;
}

.custom-marker {
    background: #fff;
      border: 1px solid #666;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px #000;
  }