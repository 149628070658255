.team_table td:first-of-type {
  text-align: center;
}

.teammates_table td:first-of-type {
  text-align: center;
  padding: 5px !important;
}

.team_icon {
  width: 32px;
  height: 32px;
}

.teammates_table .team_icon {
  width: 25px;
  height: 25px;
}

.teammates_table {
  height: 100%;
}

.teammates_table div[class^="awsui_tools-filtering"] {
  flex-basis: unset !important;
  margin-right: 10px !important;
}