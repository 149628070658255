@use '@awsui/design-tokens/index.scss' as awsui;

// 912px is the container breakpoint-s, the viewport breakpoint is 912(container width) + 40(closed side navigation width) + 4(negative margin from grid)
$viewport-breakpoint-s: 957px;
$open-navigation-width: 240px;

.custom-home__header {
  // background-color: awsui.$color-background-home-header;
  background-color: #232f3e;
  // background-image: url('../images/515885956.jpg');
  background-image: url('../images/space-image.png');
  // background-image: url('../images/515885956.jpg');
  background-position: center;
  background-size: 100vh;
  background-repeat: repeat-x;
}

.custom-home__header-title {
  color: awsui.$color-text-home-header-default;
  text-shadow: 0px 0px 8px black;
}

.custom-home__category,
.custom-home__header-sub-title {
  color: awsui.$color-text-home-header-secondary;
  text-shadow: 0px 0px 8px black;
}

@media only screen and (min-width: $viewport-breakpoint-s) {
  .custom-home__sidebar {
    // Aligns baseline of text with dark header bottom border
    margin-top: -86px;
  }
  .custom-home__header-cta {
    // Keeps 32px distance between sidebar cards
    margin-bottom: 38px;
  }
}

.custom-home__header-title > * {
  max-width: 600px;
}

.custom-home-image__placeholder:before {
  content: 'X';
  display: block;
  background-color: awsui.$color-background-input-disabled;
  color: awsui.$color-background-container-content;
  text-align: center;
  font-size: 400px;
  line-height: 400px;
}

ul.custom-list-separator {
  margin: 0;
  padding: 0;
  text-align: left !important;

  li {
    text-align: left;
    border-top: 1px solid awsui.$color-border-divider-default;
    padding: 0.8rem 0;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.main-content {
  margin-bottom: 100px;
}